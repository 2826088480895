import React from "react";
import { Route, Routes } from "react-router";
import { Header } from "../components/Home/Header";
import { AffinitiesPage } from "../pages/Affinities.page";
import { HomePage } from "../pages/Home.page";

export const AppRouter = () => (
    <div className="root">
        <Routes>
            <Route path='/' element={<HomePage />}/>
            <Route path='afinidades' element={<AffinitiesPage />} />
            <Route path='*' element={<div>Page not found</div>}/>
        </Routes>
    </div>
)