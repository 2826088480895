import React, { useState } from 'react';
import { Checkmark } from 'react-checkmark';
import { FormControl, MenuItem, Modal, Select } from "@mui/material";
import { Navigate } from 'react-router-dom';
import { getPolliticianAffinities } from '../../../adapters/PoliticianApi';
import { read } from '../../../adapters/Storage';
import './FeedbackModal.scss';

type FeedbackModalProps = {
    show: boolean;
    onClose: () => void;
}

export const FeedbackModal = ({ show, onClose }: FeedbackModalProps) => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [state, setState] = useState<string>();
    const [affinities, setAffinities] = useState([]);

    const onSelectState = (state: string) => {
        setState(state);

        setTimeout(async function() {
            const themes = await read();
            const affinities = await getPolliticianAffinities(state, themes);

            setAffinities(affinities);
            setShouldRedirect(true);
        }, 2000);
    }

    if (shouldRedirect) {
        return <Navigate to='afinidades' state={{ affinities, state }} />
    }

    return (
        <Modal 
            open={show}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modal--container">
                {state && (
                    <>
                        <Checkmark />
                        <span className="modal--text redirect--text">
                            Você será direcionado para nossa página com os(as) deputados(as) que mais te representam baseado nas suas respostas
                        </span>
                    </>
                )}
                {!state && (
                    <>
                    <h3>Estamos quase lá!</h3>
                    <span className='modal--text'>
                        Agora, selecione o seu estado de votação para que
                        possamos te mostrar os(as) deputados(as) com maior afinidade com você
                    </span>
                    
                    <span className='modal--state-label'>Estado:</span>
                    <FormControl sx={{ m: 1 }}>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="modal-select"
                            value={state}
                            onChange={(event) => onSelectState(event.target.value)}
                            >
                                <MenuItem value={"SP"}>São Paulo</MenuItem>
                                <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                                <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                                <MenuItem value={"AC"}>Acre</MenuItem>
                                <MenuItem value={"AL"}>Alagoas</MenuItem>
                                <MenuItem value={"AP"}>Amapá</MenuItem>
                                <MenuItem value={"AM"}>Amazonas</MenuItem>
                                <MenuItem value={"BA"}>Bahia</MenuItem>
                                <MenuItem value={"CE"}>Ceará</MenuItem>
                                <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                                <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                                <MenuItem value={"GO"}>Goiás</MenuItem>
                                <MenuItem value={"MA"}>Maranhão</MenuItem>
                                <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                                <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                                <MenuItem value={"PA"}>Pará</MenuItem>
                                <MenuItem value={"PB"}>Paraíba</MenuItem>
                                <MenuItem value={"PR"}>Paraná</MenuItem>
                                <MenuItem value={"PE"}>Pernambuco</MenuItem>
                                <MenuItem value={"PI"}>Piauí</MenuItem>
                                <MenuItem value={"RR"}>Roraima</MenuItem>
                                <MenuItem value={"RO"}>Rondônia</MenuItem>
                                <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                                <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                                <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                                <MenuItem value={"SE"}>Sergipe</MenuItem>
                                <MenuItem value={"TO"}>Tocantins</MenuItem>
                        </Select>
                    </FormControl>
                    </>
                )}
            </div>
        </Modal>
    )
}