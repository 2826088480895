import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Community } from "../../../domain/Theme"
import './CommunityList.scss';
import { FormControl, MenuItem, Modal, Select } from "@mui/material";
import { Navigate } from 'react-router-dom';
import { useState } from "react";

type CommunityListProps = {
    communities: Community[];
}

export const CommunityList = ({ communities }: CommunityListProps) => {
    const positionText = (community: Community) => community?.inFavor ? 'A favor' : 'contra';
    const positionIcon = (community: Community) => community?.inFavor ? <FontAwesomeIcon icon={faThumbsUp} className='inFavor' /> : <FontAwesomeIcon icon={faThumbsDown} className='against' />
    const [openModal, setOpenModal] = useState(false);
    const [community, setCommunity] = useState<Community>();

    return (
        <>
            <div className="card--communities">
                {communities.map((community) => (
                    <div key={community.name} className="card--communities-posts">
                        <img alt={`Foto Comunidade ${community.name}`} className="card--community-image" src={community.imageUrl} loading="lazy" />
                        <div className="position--container">
                            {positionIcon(community)}
                            <span className={clsx("position--text", community.inFavor ? 'inFavor' : 'against')}>{positionText(community)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-card--communities">
                <h3>Posicionamento de Comunidades Parceiras</h3>
                <div className="d-card--content-communities">
                    {communities.map((community) => (
                        <div onClick={() => {
                                setOpenModal(true);
                                setCommunity(community);
                            }} key={community.name} className='d-card--communities-posts'>
                            <div className="position--container">
                                <img alt={`Foto Comunidade ${community.name}`} className="card--community-image" src={community.imageUrl} loading="lazy" />
                                <div className="position--container">
                                    {positionIcon(community)}
                                    <span className={clsx("position--text", community.inFavor ? 'inFavor' : 'against')}>{positionText(community)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal 
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal--opinions">
                    <div style={{ display: 'flex', }}>
                        <div>
                            <img alt={`Foto Comunidade ${community?.name}`} className="card--community-image" src={community?.imageUrl} loading="lazy" />
                            <div className="position--container">
                                    {positionIcon(community)}
                                    <span className={clsx("position--text", community?.inFavor ? 'inFavor' : 'against')}>{positionText(community)}</span>
                            </div>
                        </div>


                        <div className="opinion--container">
                            <span className="opinion--title">{community?.opinionTitle}</span>
                            <span className="opinion--subtitle">{community?.opinion}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}