import axios from "axios"
import { Theme } from "../domain/Theme"

const baseUrl = 'https://api.poderdovoto.org/api/v2/themes/complete';

const getThemes = async (): Promise<Theme[]> => {
    const { data } = await axios.get(baseUrl);

    return data;
}

export { getThemes };