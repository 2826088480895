import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { createRipples } from 'react-ripples';
import { CSSTransition } from 'react-transition-group';
import './VoteContainer.scss';
import { read, writeThemeVote } from '../../../adapters/Storage';
import clsx from 'clsx';

type VoteContainerProps = {
    themeId: number;
    isInFavor: boolean | null;
}

export const VoteContainer = ({ themeId }: VoteContainerProps) => {
    const [isInFavorSelected, setIsInFavorSelected] = useState<boolean>(false);
    const [isAgainstSelected, setIsAgainstSelected] = useState<boolean>(false);

    useEffect(() => {
        read().then((votes) => {
            const isVoted = votes.find(({ id }) => themeId === id);
            if (isVoted) {
                if (isVoted.isElectorInFavor) {
                    setIsAgainstSelected(false);
                    setIsInFavorSelected(true);
                } else {
                    setIsInFavorSelected(false);
                    setIsAgainstSelected(true);
                }
            }
        })
    }, [isAgainstSelected, isInFavorSelected]);

    const Ripples = createRipples({
        color: 'rgba(255, 255, 255, .5)',
        during: 600,
    });

    const onClickInFavor = async (): Promise<void> => {
        setIsInFavorSelected(true);
        await writeThemeVote(themeId, true);
    }

    const onClickAgainst = async () => {
        setIsAgainstSelected(true);
        await writeThemeVote(themeId, false);
    }

    return (
        <div className="card--button-container">
            <CSSTransition in={isInFavorSelected} timeout={400} classNames='selected'>
            <Ripples className={clsx('button left', isAgainstSelected && 'opacity')}>
                    <button onClick={onClickInFavor} className="card--button-in-favor button">
                        <div className="card--button-text-container">
                            <span>EU SOU A FAVOR</span>
                            <FontAwesomeIcon icon={faThumbsUp} className="card--button-icon" />
                        </div>
                    </button>
                </Ripples>
            </CSSTransition>
            
                <CSSTransition in={isAgainstSelected} timeout={400} classNames='selected'>
                    <Ripples className={clsx('button right', isInFavorSelected && 'opacity')}>
                        <button onClick={onClickAgainst} className="card--button-against button">
                            <div className="card--button-text-container">
                                <span>EU SOU CONTRA</span>
                                <FontAwesomeIcon icon={faThumbsDown} className="card--button-icon" />
                            </div>
                        </button>
                    </Ripples>
                </CSSTransition>
        </div>
    )
}