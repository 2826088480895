import { useState } from "react";
import { Theme } from "../../../domain/Theme";
import { ProgressBar } from "../ProgressBar";
import { ThemeCard } from "../ThemeCard";
import './ThemeList.scss';

type ThemeListProps = {
    themes: Theme[] | undefined;
    votes: {id: number, isElectorInFavor: boolean}[];
}

export const ThemeList = ({ themes, votes }: ThemeListProps) => {
    const isVoted = (themeId: number): boolean | null => {
        const vote = votes.find((vote) => vote.id === themeId)
        if (vote) {
            return vote.isElectorInFavor;
        }
        return null;
    }

    if (!themes) {
        return <></>
    }

    return (
        <>
            {themes.map((theme, i) => (<ThemeCard key={i} theme={theme} isInFavor={isVoted(theme.id)} />))}
        </>
    )
}