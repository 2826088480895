import { FC, ReactElement } from "react";
import {ReactComponent as ReactLogo}  from "../../../images/logo-mobile.svg";
import './Header.scss';

type HeaderProps = {
    textElement: ReactElement;
}

export const Header: FC<HeaderProps> = ({ textElement }) => {
    return (
        <div className="header">
            <div className="container">
                <ReactLogo className="logo" />
                <div className="text--container">
                    <h1>Voto em quem?</h1>
                    {textElement}
                </div>
            </div>
        </div>
    )
}