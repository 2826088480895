import React, { useEffect, useState } from "react";
import { ThemeList } from "../components/Home/ThemeList";
import './Home.scss';
import { getThemes } from "../adapters/ThemeApi";
import { Theme } from "../domain/Theme";
import { clearAll, read } from "../adapters/Storage";
import { FeedbackModal } from "../components/Home/FeedbackModal";
import { ProgressBar } from "../components/Home/ProgressBar";
import { Header } from "../components/Home/Header";

export const HomePage = () => {
    const [themes, setThemes] = useState<Theme[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [votes, setVotes] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        getThemes().then((themes) => setThemes(themes));
        read().then((votes) => setVotes(votes));
    }, []);

    const seeResult = async () => {
        const votes = await read();
        console.log('votes ', votes);
        if (votes.length === 10) {
            setShowModal(true);
            return;
        }
        setErrorMessage('Você precisa votar nos 10 temas acima');
    }

    const headerTextElement = (
        <>
            <p>Vai te ajudar a encontrar políticos que têm maior grau de afinidade de propostas com você e que estão buscando a reeleição.</p>
        </>
    )

    return (
        <>
            <Header textElement={headerTextElement} />
            <div className="card--list">
                <ThemeList themes={themes} votes={votes} />
                {showModal && <FeedbackModal show={showModal} onClose={() => setShowModal(false)} /> }
                <div className="result--container">
                    <button onClick={seeResult} className={"see--result"}>
                        Ver resultado
                    </button>

                    <button onClick={() => {
                        clearAll();
                        window.location.reload();
                    }} className={"see--result restart"}>
                        RECOMEÇAR
                    </button>
                </div>
                {errorMessage && <span className="vote--error">{errorMessage}</span>}
            </div>

            <div className="footer">

            </div>
        </>
    )
}