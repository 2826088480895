import React from "react";
import './ThemeCardHeader.scss';

type ThemeCardHeaderProps = {
    origin: number;
    category: string;
    code: string;
}

export const ThemeCardHeader = ({ origin, category, code }: ThemeCardHeaderProps) => {
    return (
        <div className="card--header">
                <div className="card--header-left-content">
                    <div>
                        <span className="card--theme-origin">{origin === 1 ? 'CÂMARA' : 'SENADO'}</span>
                        <div className="card--theme-origin-line"/>
                    </div>
                    <span className="card--bullet-highlight">&nbsp; &#8226; &nbsp;</span>
                    <span className="card--category">{category}</span>
                </div>

                <div className="card--header-right-content">
                    <span>{code}</span>
                </div>
        </div>
    )
}