const ThemesVoteKey = 'ThemesVoted';

const writeThemeVote = async (id: number, isElectorInFavor: boolean): Promise<any[]> => {
    const preExistedItems = await read();
    const items = [{ id, isElectorInFavor }];
    if (preExistedItems) {
        items.push(...preExistedItems.filter((item) => item.id !== id));
    }
    
    const itemRaw = JSON.stringify(items);
    localStorage.setItem(ThemesVoteKey, itemRaw);

    return items;
}

const read = async (): Promise<any[]> => {
    const item = localStorage.getItem(ThemesVoteKey);
    if (!item) {
        return [];
    }

    return JSON.parse(item);
}

const removeVote = async (id: number): Promise<any[]> => {
    const items = await read();
    if (items) {
        const itemIndex = items.findIndex((item) => item.id === id);
        items.splice(itemIndex, 1);
        localStorage.setItem(ThemesVoteKey, JSON.stringify(items));
        return items;
    }

    return [];
}

const clearAll = (): void => {
    localStorage.clear()
}

export { writeThemeVote, removeVote, read, clearAll };
