import React from 'react';
import './Affinities.scss';
import { useLocation } from "react-router-dom";
import { Header } from '../components/Home/Header';
import {ReactComponent as LinkIcon}  from "../images/link.svg";
import {ReactComponent as Ranking}  from "../images/ranking.svg";

type AffinitiesPageProps = {
    affinities: Affinity[];
}

type Affinity = {
    affinity: number;
    imageProfile: string;
    partyName: string;
    politicianId: number;
    politicianName: string;
}

const stateMap = {
    'RJ': 'Rio de Janeiro',
    'SP': 'São Paulo',
    'MG': 'Minas Gerais',
    'AC': 'Acre',
    'AL': 'Alagoas',
    'AP': 'Amapá',
    'AM': 'Amazonas',
    'BA': 'Bahia',
    'CE': 'Ceará',
    'DF': 'Distrito Federal',
    'ES': 'Espírito Santo',
    'GO': 'Goiás',
    'MA': 'Maranhão',
    'MT': 'Mato Grosso',
    'MS': 'Mato Grosso do Sul',
    'PA': 'Pará',
    'PB': 'Paraíba',
    'PR': 'Paraná',
    'PE': 'Pernambuco',
    'PI': 'Piauí',
    'RR': 'Roraima',
    'RO': 'Rondônia',
    'RN': 'Rio Grande do Norte',
    'RS': 'Rio Grande do Sul',
    'SC': 'Santa Catarina',
    'SE': 'Sergipe',
    'TO': 'Tocantins'
}

export const AffinitiesPage = (props) => {
    const { state } = useLocation() as any;
    const { affinities } = state as AffinitiesPageProps;
    const ufState = state.state;
    const headerTextElement = (
        <>
            <p>Ajudando a encontrar os políticos que têm maior afinidade com você! Confira!</p>
        </>
    );

    const getAffinities = () => {
        const totalDiffPercentage = 10;
        const newAffs = affinities.reduce((previousVal: Affinity[], currentVal: Affinity) => {
            console.log(currentVal.affinity, previousVal[previousVal.length - 1]?.affinity);
            if (totalDiffPercentage <= previousVal.length) {
                if (previousVal[previousVal.length -1].affinity !== currentVal.affinity) {
                    return previousVal;
                }
            }
        
            previousVal.push(currentVal)
            return previousVal;
        }, []);

        return newAffs;
    }

    return (
        <>
        <Header textElement={headerTextElement} />
        <div className="affinities--container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="affinities--header">
                    <span>Top {getAffinities().length} Deputuados(as)</span>
                    <span>{stateMap[ufState]}</span>
                </div>

                <div className="affinities--list--container">
                    {getAffinities().map(({ imageProfile, politicianName, partyName, affinity }) => (
                        <div className="affinity--card">
                            <div className='affinity--level-container'>
                                <img alt='Foto de Perfil de Politico' src={imageProfile} />
                                <span className='affinity--level'>{Math.floor(affinity)}%</span>
                            </div>
                            
                            <div className="politician--info-container">
                                <span className='politician-name'>{politicianName}</span>
                                <span className='politician-party'>{partyName}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='see-also-partners'>
                <div className='see-also-container'>
                    <span>PARA POLITICOS EM PRIMEIRA ELEIÇÃO, VEJA EM</span>
                    <div className='partners-row'>
                        <a className='partners-link' href='https://matcheleitoral.folha.uol.com.br/?utm_source=votoemquem&utm_medium=poderdovoto' target='_blank' >
                            <div className='partner-container'>
                                <div className='img-container'>
                                    <img alt='Folha de Sao Paulo' src='https://www.poderdovoto.org/wp-content/uploads/2020/01/logo-folha-de-sp.png'/>
                                </div>
                                <LinkIcon />
                            </div>
                        </a>

                        {/* <a className='partners-link' href='https://politicos.org.br/Ranking?utm_source=votoemquem' target='_blank' >
                            <div className='partner-container'>
                                <div className='img-container'>
                                    <img alt='Ranking dos Politicos' src='https://politicos.org.br/_next/static/images/logo-42a9d05288904a50d6643fc99372f5d3.png'/>
                                </div>
                                <LinkIcon />
                            </div>
                        </a> */}

                        <a className='partners-link' href='https://divulgacandcontas.tse.jus.br/divulga/#/' target='_blank' >
                            <div className='partner-container'>
                                <div className='img-container'>
                                    <img alt='TSE' src='https://logodownload.org/wp-content/uploads/2018/07/tse-logo.png'/>
                                </div>
                                <LinkIcon />
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className='feedback'>
                <span className='congrats'>Parabéns!</span> 
                <span>Seu voto mais consciente ajuda a criarmos uma sociedade melhor, mais responsável e igualitária!</span>
                <br/>
                <span>Acompanhe, siga e participe com o <strong>poderdovotoapp!</strong></span>

                <div className='click-to-action'>
                    <a href="https://apple.co/2S6Lg6u" data-wpel-link="external" target="_self" rel="external noopener noreferrer">
                        <img alt='Download na Apple Store' src='https://www.poderdovoto.org/wp-content/uploads/2018/07/botao-apple-1-1-300x89.png' />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=prd.podervoto.android" data-wpel-link="external" target="_self" rel="external noopener noreferrer">
                        <img alt='Download na Google Play' src='https://www.poderdovoto.org/wp-content/uploads/2018/07/botao-google-1.png' />
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}