import axios from "axios";

const baseUrl = 'https://api.poderdovoto.org/api/v2/politicians';

type PoliticianAffinityDto = {
    affinity: number;
    politicianName: string;
    partyName: string;
    politicianId: number;
    imageProfile: string;
}

const getPolliticianAffinities = async (state: string, themeVotes: {id: number, isElectorInFavor: boolean} []): Promise<PoliticianAffinityDto[]> => {
    const { data } = await axios.get<PoliticianAffinityDto[]>(`${baseUrl}/${state}/affinities`, { params: { votes: JSON.stringify(themeVotes) } });
    return data;
}

export { getPolliticianAffinities };
