import { Theme } from "../../../domain/Theme";
import { CommunityList } from "../CommunityList";
import { VoteContainer } from "../VoteContainer";
import { ThemeCardHeader } from "./ThemeCardHeader";
import './ThemeCard.scss';
import {ReactComponent as CamaraIcon} from "../../../images/camara.svg";

type ThemeCardProps = {
    theme: Theme;
    isInFavor: boolean | null;
    isVisible?: boolean | undefined;
}

export const ThemeCard = ({ theme, isInFavor, isVisible }: ThemeCardProps) => {
    const communities = theme.posts.map((post) => ({ ...post.community, inFavor: post.isFavor, opinion: post.text, opinionTitle: post.title }));
    const commentPatri = theme.comments.find((c) => c.entity.name.toLowerCase() === 'patri');

    return (
        <>
        <div className="card--root">
            <div className="card--container">
                <ThemeCardHeader origin={theme.origin} code={theme.code} category={theme.category.name} />
                <div className="card--title-container">
                    <span className="card--title">{theme.title}</span>
                    <span className="card--subtitle">{theme.subtitle}</span>

                    {commentPatri && (
                        <>
                            {/* <span className="card--about">Sobre:</span> */}
                            <span className="card--description">{commentPatri?.text}</span>
                        </>
                    )}
                </div>

                <CommunityList communities={communities} />
            </div>
            <VoteContainer themeId={theme.id} isInFavor={isInFavor} />
        </div>
        {isVisible && (
            <div className="d-card--root">
                <div className="d-card--container">
                    <div className="d-orange--line"/>
                    <div className="d-card--content-container">
                        <div className="d-card--left-container">
                            <h1 className="title">{theme.title}</h1>
                            <span className="subtitle">{theme.subtitle}</span>
                        </div>

                        <div className="d-card--right-container">
                            <div className="origin--container">
                                <CamaraIcon />
                                <span className="origin">Câmara</span>
                            </div>
                            
                            <span className="code">{theme.code}</span>
                        </div>
                    </div>
                    <a href={theme.externalUrl} target="_blank" className="external--url">Veja o projeto completo</a>

                    <CommunityList communities={communities} />
                </div>
            </div>
        )}
        </>
        
    )
}